import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  connect() {
    super.connect();
    this.url = this.element.getAttribute('href')
  }

  urlAction(e) {
    e.preventDefault();

    axios
      .post(this.url)
      .then(() => {
        window.loadOrderTab($("a[data-target='#payment_targets']"), true);
      })
      .catch((e) => {
        Helper.flash_message('error', `Something went wrong: ${e}`)
      })
  }
}
