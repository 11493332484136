import { Controller } from "@hotwired/stimulus"

import axios from "axios";

export default class extends Controller {
  connect() {
    this.statusCheckboxes = this.element.querySelectorAll('.all_none_filter input[type=checkbox]');
  }

  search() {
    this.reloadData();
  }

  allStatuses(event) {
    event.preventDefault();
    this.changeAllFilterStatuses(true);
  }

  noneStatuses(event) {
    event.preventDefault();
    this.changeAllFilterStatuses(false);
  }

  changeAllFilterStatuses(status) {
    this.statusCheckboxes.forEach(element => element.checked = status);
    this.reloadData();
  };

  changedFilter() {
    this.reloadData();
  }

  changedVenue(event) {
    const customerSelectExists = this.element.querySelector('.filter_customer_id select') !== null;

    if (!customerSelectExists) {
      this.reloadData();
      return;
    }

    const venueId = event.target.value;

    axios
      .get(`/admin/bookings/${venueId}/customers`)
      .then(response => {
        const { data } = response;

        const items = $.map(data, (v) => {
          return '<option value=' + v[0] + '>' + v[1] + '</option>';
        });

        const el = $('#new_filter .filter_customer_id select');

        el.children().remove();
        el.append(items);

        this.reloadData();
      })
      .catch(() => this.reloadData());
  }

  changedTimePeriod(event) {
    this.reloadData();
    const checkedValue = this.element.querySelector('.filter_time_period input[type=radio]:checked').value;
    const customDates = this.element.querySelector('.custom_dates');
    customDates.classList.toggle('hidden', checkedValue !== 'custom');
  }

  reloadData() {
    const neoTableElement = this.element.closest('[data-controller="neo_table"]');

    if (neoTableElement) {
      neoTableElement.dispatchEvent(new CustomEvent("action", { detail: { info: "Hello from Child" }}));
    } else {
      console.error("No ancestor with data-controller='neo_table' found");
    }
  }
}